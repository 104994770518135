/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query GetFilterCounts {\n    healthcareIndicators {\n      assetClass {\n        items {\n          count\n          label\n        }\n      }\n      yearsSinceInvestment {\n        items {\n          count\n          label\n        }\n      }\n      stageOfBusiness {\n        items {\n          label\n          count\n        }\n      }\n      incomeGroup {\n        items {\n          label\n          count\n        }\n      }\n      primaryCountryByRegion {\n        items {\n          label\n          count\n        }\n      }\n      productType {\n        items {\n          label\n          count\n        }\n      }\n      stageValueChain {\n        items {\n          label\n          count\n        }\n      }\n      settingOfCare {\n        items {\n          label\n          count\n        }\n      }\n    }\n  }\n": types.GetFilterCountsDocument,
    "\n  query GetReportingPeriod {\n    healthcareIndicators {\n      reportingPeriod {\n        min\n        max\n      }\n    }\n  }\n": types.GetReportingPeriodDocument,
    "\n  query ValidateFilters($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      numberOfOrgs\n    }\n  }\n": types.ValidateFiltersDocument,
    "\n  query GetViewer {\n    me {\n      irisVersion\n      orgId\n      salesforceAccountId\n      userId\n      userName\n    }\n  }\n": types.GetViewerDocument,
    "\n  fragment KPIImpact_DecentJobsFragment on HealthcareIndicators {\n    decentJobs {\n      trimmedAvg\n      isDataSuppressed\n    }\n    decentJobs_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    decentJobs_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_DecentJobsFragmentFragmentDoc,
    "\n  fragment KPIImpact_EmployeesTrainedFragment on HealthcareIndicators {\n    pctEmployeesTrained {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_EmployeesTrainedFragmentFragmentDoc,
    "\n  fragment KPIImpact_IncreasingMedicalAccessFragment on HealthcareIndicators {\n    patientsReceivingNewAccess {\n      trimmedAvg\n      isDataSuppressed\n    }\n    patientsReceivingNewAccess_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    patientsReceivingNewAccess_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_IncreasingMedicalAccessFragmentFragmentDoc,
    "\n  fragment KPIImpact_InvesteeRevenueGrowthFragment on HealthcareIndicators {\n    investeeRevenueGrowth {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_InvesteeRevenueGrowthFragmentFragmentDoc,
    "\n  fragment KPIImpact_NetPromoterScoreFragment on HealthcareIndicators {\n    netPromoterScore {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_NetPromoterScoreFragmentFragmentDoc,
    "\n  fragment KPIImpact_PatientsSavingPremiumFragment on HealthcareIndicators {\n    patientsSavingPremium {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_PatientsSavingPremiumFragmentFragmentDoc,
    "\n  fragment KPIImpact_PatientsTreatedFragment on HealthcareIndicators {\n    patientsTreated {\n      trimmedAvg\n      isDataSuppressed\n    }\n    patientsTreated_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    patientsTreated_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n": types.KpiImpact_PatientsTreatedFragmentFragmentDoc,
    "\n  query ImpactKpisPage($filter: [FilterCond]) {\n    healthcareIndicators(filter: $filter) {\n      ...KPIImpact_PatientsTreatedFragment\n      ...KPIImpact_IncreasingMedicalAccessFragment\n      ...KPIImpact_PatientsSavingPremiumFragment\n      ...KPIImpact_NetPromoterScoreFragment\n      ...KPIImpact_EmployeesTrainedFragment\n      ...KPIImpact_DecentJobsFragment\n      ...KPIImpact_InvesteeRevenueGrowthFragment\n    }\n  }\n": types.ImpactKpisPageDocument,
    "\n  fragment Index_AssetClassFragment on HealthcareIndicators {\n    assetClass {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_AssetClassFragmentFragmentDoc,
    "\n  fragment Index_AssetsUnderManagementFragment on HealthcareIndicators {\n    assetsUnderManagement {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Index_AssetsUnderManagementFragmentFragmentDoc,
    "\n  fragment Index_CertificationsPresentFragment on HealthcareIndicators {\n    certifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_CertificationsPresentFragmentFragmentDoc,
    "\n  fragment Index_CountryIncomeGroupFragment on HealthcareIndicators {\n    incomeGroup {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_CountryIncomeGroupFragmentFragmentDoc,
    "\n  fragment Index_DiseaseAddressedFragment on HealthcareIndicators {\n    diseaseAddressed {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_DiseaseAddressedFragmentFragmentDoc,
    "\n  fragment Index_FinancialReturnsFragment on HealthcareIndicators {\n    targetFinancialReturns {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_FinancialReturnsFragmentFragmentDoc,
    "\n  fragment Index_GeographicFocusFragment on HealthcareIndicators {\n    geographicFocus {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_GeographicFocusFragmentFragmentDoc,
    "\n  fragment Index_HealthcareTypeFragment on HealthcareIndicators {\n    productType {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_HealthcareTypeFragmentFragmentDoc,
    "\n  fragment Index_InvestmentsRepresentedFragment on HealthcareIndicators {\n    numberOfInvestees\n  }\n": types.Index_InvestmentsRepresentedFragmentFragmentDoc,
    "\n  fragment Index_InvestorsRepresentedFragment on HealthcareIndicators {\n    numberOfOrgs\n  }\n": types.Index_InvestorsRepresentedFragmentFragmentDoc,
    "\n  fragment Index_PresenceOfCertificationsFragment on HealthcareIndicators {\n    hasCertifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_PresenceOfCertificationsFragmentFragmentDoc,
    "\n  fragment Index_RegionsAndCountriesFragment on HealthcareIndicators {\n    primaryCountryByRegion {\n      items {\n        count\n        label\n        pct\n        items {\n          count\n          label\n          pct\n        }\n      }\n    }\n  }\n": types.Index_RegionsAndCountriesFragmentFragmentDoc,
    "\n  fragment Index_SampleSizeFragment on HealthcareIndicators {\n    numberOfOrgs\n    numberOfInvestees\n    numberOfObservations\n  }\n": types.Index_SampleSizeFragmentFragmentDoc,
    "\n  fragment Index_SettingOfCareFragment on HealthcareIndicators {\n    settingOfCare {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_SettingOfCareFragmentFragmentDoc,
    "\n  fragment Index_StageOfBusinessFragment on HealthcareIndicators {\n    stageOfBusiness {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_StageOfBusinessFragmentFragmentDoc,
    "\n  fragment Index_StrategicDevelopmentGoalsFragment on HealthcareIndicators {\n    sdgs {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_StrategicDevelopmentGoalsFragmentFragmentDoc,
    "\n  fragment Index_StrategicGoalsFragment on HealthcareIndicators {\n    strategicGoals {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_StrategicGoalsFragmentFragmentDoc,
    "\n  fragment Index_TargetStakeholderFragment on HealthcareIndicators {\n    targetStakeholder {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_TargetStakeholderFragmentFragmentDoc,
    "\n  query IndexPage($filter: [FilterCond]) {\n    healthcareIndicators(filter: $filter) {\n      ...Index_SampleSizeFragment\n      ...Index_AssetsUnderManagementFragment\n      ...Index_FinancialReturnsFragment\n      ...Index_AssetClassFragment\n      ...Index_CountryIncomeGroupFragment\n      ...Index_GeographicFocusFragment\n      ...Index_RegionsAndCountriesFragment\n      ...Index_StrategicGoalsFragment\n      ...Index_StrategicDevelopmentGoalsFragment\n      ...Index_HealthcareTypeFragment\n      ...Index_StageOfBusinessFragment\n      ...Index_SettingOfCareFragment\n      ...Index_PresenceOfCertificationsFragment\n      ...Index_CertificationsPresentFragment\n      ...Index_TargetStakeholderFragment\n      ...Index_DiseaseAddressedFragment\n    }\n  }\n": types.IndexPageDocument,
    "\n  fragment Sdg_DecentJobsFragment on HealthcareIndicators {\n    decentJobs_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Sdg_DecentJobsFragmentFragmentDoc,
    "\n  fragment Sdg_IncreasingMedicalAccessFragment on HealthcareIndicators {\n    patientsReceivingNewAccess_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Sdg_IncreasingMedicalAccessFragmentFragmentDoc,
    "\n  fragment Sdg_InvesteeRevenueGrowthFragment on HealthcareIndicators {\n    investeeRevenueGrowth {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Sdg_InvesteeRevenueGrowthFragmentFragmentDoc,
    "\n  fragment Sdg_PatientsSavingPremiumFragment on HealthcareIndicators {\n    patientsSavingPremium {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Sdg_PatientsSavingPremiumFragmentFragmentDoc,
    "\n  fragment Sdg_PatientsTreatedFragment on HealthcareIndicators {\n    patientsTreated_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Sdg_PatientsTreatedFragmentFragmentDoc,
    "\n  query SdgPage($filter: [FilterCond]) {\n    healthcareIndicators(filter: $filter) {\n      ...Sdg_PatientsTreatedFragment\n      ...Sdg_IncreasingMedicalAccessFragment\n      ...Sdg_PatientsSavingPremiumFragment\n      ...Sdg_DecentJobsFragment\n      ...Sdg_InvesteeRevenueGrowthFragment\n    }\n  }\n": types.SdgPageDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFilterCounts {\n    healthcareIndicators {\n      assetClass {\n        items {\n          count\n          label\n        }\n      }\n      yearsSinceInvestment {\n        items {\n          count\n          label\n        }\n      }\n      stageOfBusiness {\n        items {\n          label\n          count\n        }\n      }\n      incomeGroup {\n        items {\n          label\n          count\n        }\n      }\n      primaryCountryByRegion {\n        items {\n          label\n          count\n        }\n      }\n      productType {\n        items {\n          label\n          count\n        }\n      }\n      stageValueChain {\n        items {\n          label\n          count\n        }\n      }\n      settingOfCare {\n        items {\n          label\n          count\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetFilterCounts {\n    healthcareIndicators {\n      assetClass {\n        items {\n          count\n          label\n        }\n      }\n      yearsSinceInvestment {\n        items {\n          count\n          label\n        }\n      }\n      stageOfBusiness {\n        items {\n          label\n          count\n        }\n      }\n      incomeGroup {\n        items {\n          label\n          count\n        }\n      }\n      primaryCountryByRegion {\n        items {\n          label\n          count\n        }\n      }\n      productType {\n        items {\n          label\n          count\n        }\n      }\n      stageValueChain {\n        items {\n          label\n          count\n        }\n      }\n      settingOfCare {\n        items {\n          label\n          count\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetReportingPeriod {\n    healthcareIndicators {\n      reportingPeriod {\n        min\n        max\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetReportingPeriod {\n    healthcareIndicators {\n      reportingPeriod {\n        min\n        max\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ValidateFilters($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      numberOfOrgs\n    }\n  }\n"): (typeof documents)["\n  query ValidateFilters($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      numberOfOrgs\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetViewer {\n    me {\n      irisVersion\n      orgId\n      salesforceAccountId\n      userId\n      userName\n    }\n  }\n"): (typeof documents)["\n  query GetViewer {\n    me {\n      irisVersion\n      orgId\n      salesforceAccountId\n      userId\n      userName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_DecentJobsFragment on HealthcareIndicators {\n    decentJobs {\n      trimmedAvg\n      isDataSuppressed\n    }\n    decentJobs_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    decentJobs_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_DecentJobsFragment on HealthcareIndicators {\n    decentJobs {\n      trimmedAvg\n      isDataSuppressed\n    }\n    decentJobs_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    decentJobs_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_EmployeesTrainedFragment on HealthcareIndicators {\n    pctEmployeesTrained {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_EmployeesTrainedFragment on HealthcareIndicators {\n    pctEmployeesTrained {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_IncreasingMedicalAccessFragment on HealthcareIndicators {\n    patientsReceivingNewAccess {\n      trimmedAvg\n      isDataSuppressed\n    }\n    patientsReceivingNewAccess_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    patientsReceivingNewAccess_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_IncreasingMedicalAccessFragment on HealthcareIndicators {\n    patientsReceivingNewAccess {\n      trimmedAvg\n      isDataSuppressed\n    }\n    patientsReceivingNewAccess_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    patientsReceivingNewAccess_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_InvesteeRevenueGrowthFragment on HealthcareIndicators {\n    investeeRevenueGrowth {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_InvesteeRevenueGrowthFragment on HealthcareIndicators {\n    investeeRevenueGrowth {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_NetPromoterScoreFragment on HealthcareIndicators {\n    netPromoterScore {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_NetPromoterScoreFragment on HealthcareIndicators {\n    netPromoterScore {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_PatientsSavingPremiumFragment on HealthcareIndicators {\n    patientsSavingPremium {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_PatientsSavingPremiumFragment on HealthcareIndicators {\n    patientsSavingPremium {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment KPIImpact_PatientsTreatedFragment on HealthcareIndicators {\n    patientsTreated {\n      trimmedAvg\n      isDataSuppressed\n    }\n    patientsTreated_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    patientsTreated_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment KPIImpact_PatientsTreatedFragment on HealthcareIndicators {\n    patientsTreated {\n      trimmedAvg\n      isDataSuppressed\n    }\n    patientsTreated_n {\n      trimmedAvg\n      isDataSuppressed\n    }\n    patientsTreated_pace {\n      trimmedAvg\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ImpactKpisPage($filter: [FilterCond]) {\n    healthcareIndicators(filter: $filter) {\n      ...KPIImpact_PatientsTreatedFragment\n      ...KPIImpact_IncreasingMedicalAccessFragment\n      ...KPIImpact_PatientsSavingPremiumFragment\n      ...KPIImpact_NetPromoterScoreFragment\n      ...KPIImpact_EmployeesTrainedFragment\n      ...KPIImpact_DecentJobsFragment\n      ...KPIImpact_InvesteeRevenueGrowthFragment\n    }\n  }\n"): (typeof documents)["\n  query ImpactKpisPage($filter: [FilterCond]) {\n    healthcareIndicators(filter: $filter) {\n      ...KPIImpact_PatientsTreatedFragment\n      ...KPIImpact_IncreasingMedicalAccessFragment\n      ...KPIImpact_PatientsSavingPremiumFragment\n      ...KPIImpact_NetPromoterScoreFragment\n      ...KPIImpact_EmployeesTrainedFragment\n      ...KPIImpact_DecentJobsFragment\n      ...KPIImpact_InvesteeRevenueGrowthFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_AssetClassFragment on HealthcareIndicators {\n    assetClass {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_AssetClassFragment on HealthcareIndicators {\n    assetClass {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_AssetsUnderManagementFragment on HealthcareIndicators {\n    assetsUnderManagement {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_AssetsUnderManagementFragment on HealthcareIndicators {\n    assetsUnderManagement {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_CertificationsPresentFragment on HealthcareIndicators {\n    certifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_CertificationsPresentFragment on HealthcareIndicators {\n    certifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_CountryIncomeGroupFragment on HealthcareIndicators {\n    incomeGroup {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_CountryIncomeGroupFragment on HealthcareIndicators {\n    incomeGroup {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_DiseaseAddressedFragment on HealthcareIndicators {\n    diseaseAddressed {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_DiseaseAddressedFragment on HealthcareIndicators {\n    diseaseAddressed {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_FinancialReturnsFragment on HealthcareIndicators {\n    targetFinancialReturns {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_FinancialReturnsFragment on HealthcareIndicators {\n    targetFinancialReturns {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_GeographicFocusFragment on HealthcareIndicators {\n    geographicFocus {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_GeographicFocusFragment on HealthcareIndicators {\n    geographicFocus {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_HealthcareTypeFragment on HealthcareIndicators {\n    productType {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_HealthcareTypeFragment on HealthcareIndicators {\n    productType {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_InvestmentsRepresentedFragment on HealthcareIndicators {\n    numberOfInvestees\n  }\n"): (typeof documents)["\n  fragment Index_InvestmentsRepresentedFragment on HealthcareIndicators {\n    numberOfInvestees\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_InvestorsRepresentedFragment on HealthcareIndicators {\n    numberOfOrgs\n  }\n"): (typeof documents)["\n  fragment Index_InvestorsRepresentedFragment on HealthcareIndicators {\n    numberOfOrgs\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_PresenceOfCertificationsFragment on HealthcareIndicators {\n    hasCertifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_PresenceOfCertificationsFragment on HealthcareIndicators {\n    hasCertifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_RegionsAndCountriesFragment on HealthcareIndicators {\n    primaryCountryByRegion {\n      items {\n        count\n        label\n        pct\n        items {\n          count\n          label\n          pct\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment Index_RegionsAndCountriesFragment on HealthcareIndicators {\n    primaryCountryByRegion {\n      items {\n        count\n        label\n        pct\n        items {\n          count\n          label\n          pct\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_SampleSizeFragment on HealthcareIndicators {\n    numberOfOrgs\n    numberOfInvestees\n    numberOfObservations\n  }\n"): (typeof documents)["\n  fragment Index_SampleSizeFragment on HealthcareIndicators {\n    numberOfOrgs\n    numberOfInvestees\n    numberOfObservations\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_SettingOfCareFragment on HealthcareIndicators {\n    settingOfCare {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_SettingOfCareFragment on HealthcareIndicators {\n    settingOfCare {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_StageOfBusinessFragment on HealthcareIndicators {\n    stageOfBusiness {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_StageOfBusinessFragment on HealthcareIndicators {\n    stageOfBusiness {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_StrategicDevelopmentGoalsFragment on HealthcareIndicators {\n    sdgs {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_StrategicDevelopmentGoalsFragment on HealthcareIndicators {\n    sdgs {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_StrategicGoalsFragment on HealthcareIndicators {\n    strategicGoals {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_StrategicGoalsFragment on HealthcareIndicators {\n    strategicGoals {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_TargetStakeholderFragment on HealthcareIndicators {\n    targetStakeholder {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_TargetStakeholderFragment on HealthcareIndicators {\n    targetStakeholder {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query IndexPage($filter: [FilterCond]) {\n    healthcareIndicators(filter: $filter) {\n      ...Index_SampleSizeFragment\n      ...Index_AssetsUnderManagementFragment\n      ...Index_FinancialReturnsFragment\n      ...Index_AssetClassFragment\n      ...Index_CountryIncomeGroupFragment\n      ...Index_GeographicFocusFragment\n      ...Index_RegionsAndCountriesFragment\n      ...Index_StrategicGoalsFragment\n      ...Index_StrategicDevelopmentGoalsFragment\n      ...Index_HealthcareTypeFragment\n      ...Index_StageOfBusinessFragment\n      ...Index_SettingOfCareFragment\n      ...Index_PresenceOfCertificationsFragment\n      ...Index_CertificationsPresentFragment\n      ...Index_TargetStakeholderFragment\n      ...Index_DiseaseAddressedFragment\n    }\n  }\n"): (typeof documents)["\n  query IndexPage($filter: [FilterCond]) {\n    healthcareIndicators(filter: $filter) {\n      ...Index_SampleSizeFragment\n      ...Index_AssetsUnderManagementFragment\n      ...Index_FinancialReturnsFragment\n      ...Index_AssetClassFragment\n      ...Index_CountryIncomeGroupFragment\n      ...Index_GeographicFocusFragment\n      ...Index_RegionsAndCountriesFragment\n      ...Index_StrategicGoalsFragment\n      ...Index_StrategicDevelopmentGoalsFragment\n      ...Index_HealthcareTypeFragment\n      ...Index_StageOfBusinessFragment\n      ...Index_SettingOfCareFragment\n      ...Index_PresenceOfCertificationsFragment\n      ...Index_CertificationsPresentFragment\n      ...Index_TargetStakeholderFragment\n      ...Index_DiseaseAddressedFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Sdg_DecentJobsFragment on HealthcareIndicators {\n    decentJobs_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Sdg_DecentJobsFragment on HealthcareIndicators {\n    decentJobs_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Sdg_IncreasingMedicalAccessFragment on HealthcareIndicators {\n    patientsReceivingNewAccess_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Sdg_IncreasingMedicalAccessFragment on HealthcareIndicators {\n    patientsReceivingNewAccess_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Sdg_InvesteeRevenueGrowthFragment on HealthcareIndicators {\n    investeeRevenueGrowth {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Sdg_InvesteeRevenueGrowthFragment on HealthcareIndicators {\n    investeeRevenueGrowth {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Sdg_PatientsSavingPremiumFragment on HealthcareIndicators {\n    patientsSavingPremium {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Sdg_PatientsSavingPremiumFragment on HealthcareIndicators {\n    patientsSavingPremium {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Sdg_PatientsTreatedFragment on HealthcareIndicators {\n    patientsTreated_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Sdg_PatientsTreatedFragment on HealthcareIndicators {\n    patientsTreated_pacePct {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SdgPage($filter: [FilterCond]) {\n    healthcareIndicators(filter: $filter) {\n      ...Sdg_PatientsTreatedFragment\n      ...Sdg_IncreasingMedicalAccessFragment\n      ...Sdg_PatientsSavingPremiumFragment\n      ...Sdg_DecentJobsFragment\n      ...Sdg_InvesteeRevenueGrowthFragment\n    }\n  }\n"): (typeof documents)["\n  query SdgPage($filter: [FilterCond]) {\n    healthcareIndicators(filter: $filter) {\n      ...Sdg_PatientsTreatedFragment\n      ...Sdg_IncreasingMedicalAccessFragment\n      ...Sdg_PatientsSavingPremiumFragment\n      ...Sdg_DecentJobsFragment\n      ...Sdg_InvesteeRevenueGrowthFragment\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;