import React, { useEffect } from 'react';
import { ChartCard, HorizontalBars, InfoTooltip } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';
import {
  type RelativePerformance,
  getRelativePerformance,
} from '../-helpers/getRelativePerformance';
import { useSortedChartData } from '../-hooks/useSortedChartData';
import { OptionalKey } from '../route';

export const KPIImpact_InvesteeRevenueGrowthFragment = gql(/* GraphQL */ `
  fragment KPIImpact_InvesteeRevenueGrowthFragment on HealthcareIndicators {
    investeeRevenueGrowth {
      trimmedAvg
      isDataSuppressed
    }
  }
`);

type InvesteeRevenueGrowthProps = {
  className?: string;
  indicators: FragmentType<typeof KPIImpact_InvesteeRevenueGrowthFragment>;
  viewerIndicators?: FragmentType<typeof KPIImpact_InvesteeRevenueGrowthFragment>;
  investmentLevel: OptionalKey;
  onEvaluatePerformance: (key: string, performance: RelativePerformance) => void;
};

export function InvesteeRevenueGrowth({
  indicators,
  viewerIndicators,
  investmentLevel,
  onEvaluatePerformance,
  className,
}: InvesteeRevenueGrowthProps) {
  const benchmarkData = useFragment(KPIImpact_InvesteeRevenueGrowthFragment, indicators);
  const viewerData = useFragment(KPIImpact_InvesteeRevenueGrowthFragment, viewerIndicators);

  const benchmarkDataMap = {
    company: benchmarkData.investeeRevenueGrowth,
    investment: benchmarkData.investeeRevenueGrowth,
    rate_of_change: benchmarkData.investeeRevenueGrowth,
  };

  const viewerDataMap = {
    company: viewerData?.investeeRevenueGrowth,
    investment: viewerData?.investeeRevenueGrowth,
    rate_of_change: viewerData?.investeeRevenueGrowth,
  };

  const selectedBenchmarkData = benchmarkDataMap[investmentLevel];
  const selectedViewerData = viewerDataMap[investmentLevel];

  const { t, title, titleTooltipKPI } = useChartTranslations('investeeRevenueGrowth');

  const getDisplayValue = (value?: number | null): string => {
    if (value == null) return '';
    const strValue = (value * 100).toLocaleString('en', { maximumFractionDigits: 0 });
    return t('displayValue', { value: strValue }) ?? strValue;
  };

  const { sortedChartData } = useSortedChartData({
    selectedBenchmarkData,
    selectedViewerData,
    getDisplayValue,
  });

  const relativePerformance = getRelativePerformance(
    selectedBenchmarkData?.trimmedAvg,
    selectedViewerData?.trimmedAvg
  );

  useEffect(() => {
    onEvaluatePerformance('investeeRevenueGrowth', relativePerformance);
  }, [onEvaluatePerformance, relativePerformance]);

  return (
    <ChartCard className={className}>
      <ChartCard.Header className="flex items-center justify-between">
        <ChartCard.Title>
          {title}
          {titleTooltipKPI && <InfoTooltip>{titleTooltipKPI}</InfoTooltip>}
        </ChartCard.Title>
        {/* <Button variant="outline" className="shrink-0 font-semibold uppercase">
          view details
        </Button> */}
      </ChartCard.Header>
      <ChartCard.Body isDataSuppressed={selectedBenchmarkData.isDataSuppressed}>
        <HorizontalBars min={0} max={1} showInlineDisplayValue={true}>
          {sortedChartData.map((data, index) => (
            <HorizontalBars.Item
              key={index}
              label={data.label}
              value={data.value}
              displayValue={data.displayValue}
              color={data.color}
              isDashed={data.isDashed}
            />
          ))}
        </HorizontalBars>

        {/* <Link href="/impact-kpis/investee-revenue-growth" className="mt-5 lg:hidden">
          <Button variant="outline" size="small" className="shrink-0 font-semibold uppercase">
            view details
          </Button>
        </Link> */}
      </ChartCard.Body>
    </ChartCard>
  );
}
