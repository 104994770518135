import { faAngleDown } from '@fa-icons/classic/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createFileRoute } from '@tanstack/react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BorderBox, InfoTooltip, SectionTitle } from 'ui-v2';
import { cn } from 'utils';

import { InvalidFilters } from '@/components/InvalidFilters';
import { useIsValidFilters } from '@/hooks/useIsValidFilters';

import { PatientsTreated } from './-components/PatientsTreated';
import { IncreasingMedicalAccess } from './-components/IncreasingMedicalAccess';
import { PatientsSavingPremium } from './-components/PatientsSavingPremium';
import { DecentJobs } from './-components/DecentJobs';
import { InvesteeRevenueGrowth } from './-components/InvesteeRevenueGrowth';
import { useSdgData } from './-hooks/useSdgData';

export const Route = createFileRoute('/sustainable-development-goals')({
  component: Sdg,
});

function Sdg() {
  const { t } = useTranslation();

  const { loading, isValid } = useIsValidFilters();
  const { benchmarkData, viewerData } = useSdgData({ skip: !isValid || loading });

  if (loading) return null;
  if (!isValid) return <InvalidFilters />;
  if (!benchmarkData) return null;

  const chartProps = {
    indicators: benchmarkData,
    viewerIndicators: viewerData ?? undefined,
  };

  const titleTooltip = t('sdgPage_titleTooltip');

  return (
    <BorderBox>
      {/* header */}
      <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
        <div className="flex flex-wrap items-baseline gap-x-3 gap-y-2">
          <div className="flex items-center gap-1.5">
            <SectionTitle>{t('sdgPage_title')}</SectionTitle>
            {titleTooltip && <InfoTooltip>{titleTooltip}</InfoTooltip>}
          </div>
          <p className="text-gray-900">{t('sdgPage_subtitle')}</p>
        </div>
        <div className="min-w-[18.75rem]">
          <button className="focus:ring-barbie flex w-full items-center justify-between rounded-lg border border-gray-300 px-4 py-1.5 text-left focus:outline-none focus:ring-2">
            <div className="flex flex-col">
              <span className="text-barbie text-[0.625rem] uppercase leading-[0.875rem]">
                SDG Level
              </span>
              <span className="text-[0.75rem] font-normal leading-4 text-gray-900">Global</span>
            </div>
            <FontAwesomeIcon
              icon={faAngleDown}
              className={cn('text-barbie transform transition-transform duration-200', {
                // 'rotate-180': isOpen,
              })}
            />
          </button>
        </div>
      </div>
      {/* body */}
      <div className="grid grid-cols-1 gap-6 pt-9 md:grid-cols-2">
        <PatientsTreated {...chartProps} />
        <IncreasingMedicalAccess {...chartProps} />
        <PatientsSavingPremium {...chartProps} />
        <DecentJobs {...chartProps} />
        <InvesteeRevenueGrowth {...chartProps} />
      </div>
    </BorderBox>
  );
}
