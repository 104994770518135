import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import { ChartCard, generateChartColors, HorizontalBars, InfoTooltip } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';

export const Index_CertificationsPresentFragment = gql(/* GraphQL */ `
  fragment Index_CertificationsPresentFragment on HealthcareIndicators {
    certifications {
      items {
        count
        label
        pct
      }
      isDataSuppressed
    }
  }
`);

type CertificationsPresentProps = {
  className?: string;
  indicators: FragmentType<typeof Index_CertificationsPresentFragment>;
};

export function CertificationsPresent({ indicators, className }: CertificationsPresentProps) {
  const { certifications } = useFragment(Index_CertificationsPresentFragment, indicators);

  const { title, subtitle, chartTooltip, getOptionLabel, getOptionTooltip } =
    useChartTranslations('certificationsPresent');

  const sortedItems = useMemo(() => {
    const colors = generateChartColors(certifications.items.length);
    const sorted = orderBy(certifications.items, ['pct'], ['desc']);
    return sorted.map((x, i) => ({ ...x, color: colors[i] }));
  }, [certifications.items]);

  const min = Math.min(0, ...certifications.items.map((item) => item?.count ?? 0));
  const max = Math.max(...certifications.items.map((item) => item?.count ?? 0));

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <ChartCard.Title>
          {title}
          {chartTooltip && <InfoTooltip>{chartTooltip}</InfoTooltip>}
        </ChartCard.Title>
        {subtitle && <ChartCard.Subtitle>{subtitle}</ChartCard.Subtitle>}
      </ChartCard.Header>
      <ChartCard.Body isDataSuppressed={certifications.isDataSuppressed}>
        <HorizontalBars min={min} max={max} showInlineDisplayValue={false}>
          {sortedItems.map((item, i) => {
            if (!item) return null;
            return (
              <HorizontalBars.Item
                key={i}
                label={getOptionLabel(item.label)}
                tooltipLabel={getOptionTooltip(item.label)}
                value={item.count ?? null}
                displayValue={item.count?.toLocaleString() ?? ''}
                color={item.color}
              />
            );
          })}
        </HorizontalBars>
      </ChartCard.Body>
    </ChartCard>
  );
}
