import { QueryHookOptions, useQuery } from '@apollo/client';

import { gql } from '@/generated';
import { useFilterConditions } from '@/contexts';
import { ImpactKpisPageQuery, ImpactKpisPageQueryVariables } from '@/generated/graphql';

const QUERY = gql(/* GraphQL */ `
  query ImpactKpisPage($filter: [FilterCond]) {
    healthcareIndicators(filter: $filter) {
      ...KPIImpact_PatientsTreatedFragment
      ...KPIImpact_IncreasingMedicalAccessFragment
      ...KPIImpact_PatientsSavingPremiumFragment
      ...KPIImpact_NetPromoterScoreFragment
      ...KPIImpact_EmployeesTrainedFragment
      ...KPIImpact_DecentJobsFragment
      ...KPIImpact_InvesteeRevenueGrowthFragment
    }
  }
`);

export function useImpactKpisData(
  options: QueryHookOptions<ImpactKpisPageQuery, ImpactKpisPageQueryVariables> = {}
) {
  const { viewer, benchmark } = useFilterConditions();
  const benchmarkQuery = useQuery(QUERY, { variables: { filter: benchmark }, ...options });
  const viewerQuery = useQuery(QUERY, { variables: { filter: viewer }, skip: !viewer });

  return {
    loading: benchmarkQuery.loading || viewerQuery.loading,
    benchmarkQuery,
    viewerQuery,
    benchmarkData: benchmarkQuery.data?.healthcareIndicators,
    viewerData: viewerQuery.data?.healthcareIndicators,
  };
}
