import { useQuery } from '@apollo/client';

import { gql } from '@/generated';

const GET_FILTER_COUNTS = gql(/* GraphQL */ `
  query GetFilterCounts {
    healthcareIndicators {
      assetClass {
        items {
          count
          label
        }
      }
      yearsSinceInvestment {
        items {
          count
          label
        }
      }
      stageOfBusiness {
        items {
          label
          count
        }
      }
      incomeGroup {
        items {
          label
          count
        }
      }
      primaryCountryByRegion {
        items {
          label
          count
        }
      }
      productType {
        items {
          label
          count
        }
      }
      stageValueChain {
        items {
          label
          count
        }
      }
      settingOfCare {
        items {
          label
          count
        }
      }
    }
  }
`);
export function useFilterCounts() {
  const query = useQuery(GET_FILTER_COUNTS);
  return {
    ...query,
    indicators: query.data?.healthcareIndicators,
  };
}
