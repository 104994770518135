import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, concat } from '@apollo/client';

import config from './config';

const httpLink = new HttpLink(config.apollo);

// Adds the authorization header
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const authorization = window.__benchmarkConfig.auth_token;
    if (!authorization) return { headers };
    return { headers: { ...headers, authorization } };
  });

  return forward(operation);
});

const createApolloClient = () =>
  new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            agricultureIndicators: { merge: true },
            energyIndicators: { merge: true },
            financeIndicators: { merge: true },
            forestryIndicators: { merge: true },
            healthcareIndicators: { merge: true },
            me: { merge: true },
          },
        },
      },
    }),
  });

export default createApolloClient;
