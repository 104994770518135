import { alpha3ToAlpha2, getName } from 'i18n-iso-countries';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { ChartCard, generateChartColors, HorizontalBars, InfoTooltip, Tooltip } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';

export const Index_RegionsAndCountriesFragment = gql(/* GraphQL */ `
  fragment Index_RegionsAndCountriesFragment on HealthcareIndicators {
    primaryCountryByRegion {
      items {
        count
        label
        pct
        items {
          count
          label
          pct
        }
      }
    }
  }
`);

type RegionsAndCountriesProps = {
  className?: string;
  indicators: FragmentType<typeof Index_RegionsAndCountriesFragment>;
};

export function RegionsAndCountries({ indicators, className }: RegionsAndCountriesProps) {
  const { primaryCountryByRegion } = useFragment(Index_RegionsAndCountriesFragment, indicators);

  const { title, subtitle, chartTooltip, getOptionLabel, getOptionTooltip } =
    useChartTranslations('region');

  const formattedChartData = useMemo(() => {
    const dataWithSortedCountries = primaryCountryByRegion.items
      .filter((item) => !!item)
      .map((item) => {
        const sortedCountries = item.items.length ? orderBy(item.items, 'count', 'desc') : [];
        const mappedCountries = sortedCountries.map((item) => ({
          ...item,
          label: item?.label ?? '',
        }));
        return {
          ...item,
          items: mappedCountries,
        };
      });

    return dataWithSortedCountries;
  }, [primaryCountryByRegion.items]);

  const sortedItems = useMemo(() => {
    const colors = generateChartColors(formattedChartData.length);
    const sorted = orderBy(formattedChartData, ['pct'], ['desc']);
    return sorted.map((x, i) => ({ ...x, color: colors[i] }));
  }, [formattedChartData]);

  const getCountryTooltipProps = (
    label = '',
    value = 0
  ): { label: string; value: string | number } => ({
    label: getOptionTooltip(getName(label ?? '', 'en') || ''),
    value: value < 5 ? 'Insufficient data' : value,
  });

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <ChartCard.Title>
          {title}
          {chartTooltip && <InfoTooltip>{chartTooltip}</InfoTooltip>}
        </ChartCard.Title>
        {subtitle && <ChartCard.Subtitle>{subtitle}</ChartCard.Subtitle>}
      </ChartCard.Header>
      <ChartCard.Body>
        <HorizontalBars min={0} max={100} showInlineDisplayValue={false}>
          {sortedItems.map((item, i) => {
            if (!item) return null;

            const countriesToShow = item.items.slice(0, 3).reverse();
            const countriesToHide = item.items.slice(3);

            return (
              <HorizontalBars.Item
                key={i}
                label={getOptionLabel(item.label)}
                tooltipLabel={getOptionTooltip(item.label)}
                value={item.pct}
                displayValue={item.count.toLocaleString()}
                color={item.color}
              >
                <div className="flex items-center gap-3">
                  {countriesToHide.length > 0 && (
                    <Tooltip variant="tooltip">
                      <Tooltip.Trigger>
                        <span className="text-barbie order-2 inline-block lg:order-1">
                          +{countriesToHide.length}
                        </span>
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        <div className="space-y-1">
                          {countriesToHide.map((country, j) => (
                            <Tooltip.ChartValue
                              key={`${i}:${j}`}
                              {...getCountryTooltipProps(country.label, country.count)}
                            />
                          ))}
                        </div>
                      </Tooltip.Content>
                    </Tooltip>
                  )}
                  <div className="order-1 flex gap-1 lg:order-2">
                    {countriesToShow.map((item, i) => {
                      if (!item.label) return null;
                      return (
                        <Tooltip variant="tooltip" key={i}>
                          <Tooltip.Trigger>
                            <div>
                              <CircleFlag
                                countryCode={alpha3ToAlpha2(item.label)?.toLowerCase() ?? ''}
                                className="size-4 lg:size-6"
                              />
                            </div>
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            <Tooltip.ChartValue
                              {...getCountryTooltipProps(item.label, item.count)}
                            />
                          </Tooltip.Content>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
              </HorizontalBars.Item>
            );
          })}
        </HorizontalBars>
      </ChartCard.Body>
    </ChartCard>
  );
}
